import React, { useState } from "react";
import Axios from "axios";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
// import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import { CircularProgress, FormLabel } from '@material-ui/core';
import { IPresenterProps } from "./types";
import StatusSMS from "../statusSMS";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },

  linearProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default (props: IPresenterProps) => {
  const classes = useStyles();
  const [finalizarActive, setFinalizarActive] = useState(false);
  return (
    <div>
      <Toolbar variant="regular" style={{ justifyContent: "center" }}>
        <Typography variant="h5" color="inherit">
          REVISIÓN DE DISPOSITIVOS
        </Typography>
      </Toolbar>
      {props.vehiculo && (
        <Grid>
          <Grid container justifyContent={"center"}>
            <Grid className={classes.root} item sm>
              <Container>
                <TextField
                  style={{
                    width: "100%",
                  }}
                  required
                  label="Número de instalación"
                  value={props.vehiculo.solicitudId}
                  disabled
                />
                {/* <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Cliente"
                                    disabled
                                    value={props.cliente.razonSocial === '' || props.cliente.razonSocial === 'null' || props.cliente.razonSocial === null ? `${props.cliente.nombres} ${props.cliente.apellidos}` : props.cliente.razonSocial}
                                    variant="outlined" /> */}
                {/* <TextField
                                    onChange={(event) => {
                                        props.setEmail(event.target.value);
                                    }}
                                    required
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Email"
                                    disabled={false}
                                    value={props.cliente.email}
                                    variant="outlined" /> */}
                <TextField
                  style={{
                    width: "100%",
                  }}
                  label="Vehículo"
                  disabled
                  value={`${props.vehiculo.marca} ${props.vehiculo.modelo} ${props.vehiculo.anio}`}
                  variant="outlined"
                />
              </Container>
              <Container>
                <TextField
                  inputRef={(input) => {
                    if (input != null && props.cambiarDispositivo) {
                      input.focus();
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                  label={"Número de serie"}
                  placeholder="Ingrese el número de serie"
                  value={props.dispositivo.serial}
                  onChange={(e) => props.setNumeroSerie(e.target.value)}
                  disabled={!props.cambiarDispositivo}
                  onKeyPress={(e) =>
                    e.key === "Enter" && props.onObtenerDispositivo()
                  }
                  error={props.errorObtnerDispositivo ? true : false}
                  helperText={props.errorObtnerDispositivo}
                />
                {props.cambiarDispositivo && props.loading !== "device" && (
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => props.onObtenerDispositivo()}
                    disabled={props.loading !== "" ? true : false}
                  >
                    Buscar
                  </Button>
                )}
                {props.cambiarDispositivo && props.loading === "device" && (
                  <div className={classes.linearProgress}>
                    <LinearProgress />
                  </div>
                )}

                {props.cambiarDispositivo && (
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    label={"SIM ID"}
                    disabled={true}
                    value={props.dispositivo.simId}
                    variant="outlined"
                  />
                )}
                <TextField
                  required
                  style={{
                    width: "100%",
                  }}
                  label="Placa del vehículo"
                  disabled
                  value={props.vehiculo.placa || ""}
                  onChange={(e) => {
                    const withNoSpaces = e.target.value.trim();
                    props.setPlaca(withNoSpaces);
                  }}
                />
                <TextField
                  style={{
                    width: "100%",
                  }}
                  label="Número de Chasis"
                  disabled
                  value={props.vehiculo.chasis || ""}
                  onChange={(e) => {
                    const withNoSpaces = e.target.value.replace(/\s+/g, "");
                    props.setChasis(withNoSpaces);
                  }}
                />
                {/* <TextField style={{
                                    width: '100%'
                                }} required
                                    label="Nombre del instalador"
                                    id="instalador"
                                    value={props.dispositivo.instalador} 
                                    onChange={(e) => props.setInstalador(e.target.value)}
                                /> */}

                {/* <span style={{ color: 'red', marginTop: 20, fontSize: 16, fontWeight: 'bold' }}>{props.errorConfigurar}</span>
                                {props.loading !== 'setup' && <Button onClick={() => props.onConfigurarDispositivo()} variant={"contained"} color={"primary"} disabled={props.cliente.email === '' || props.dispositivo.simId === '' || (props.vehiculo.placa === '' && props.vehiculo.chasis === '')}>Iniciar Configuración</Button>}

                                {props.loading === 'setup' &&
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                                        <h4>Configurando</h4>

                                        <div className={classes.linearProgress}>
                                            <LinearProgress />
                                        </div>
                                    </div>} */}
              </Container>
              <Container>
                {props.cambiarDispositivo ? (
                  <>
                    {props.loading !== "setup" && (
                      <>
                        <Button
                          onClick={() => props.setCambiarDispositivo(false)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color={"primary"}
                          variant={"contained"}
                          onClick={props.onConfigurarDispositivo}
                          disabled={
                            props.dispositivo.simId === "" ? true : false
                          }
                        >
                          Cambiar
                        </Button>
                      </>
                    )}

                    {props.cambiarDispositivo && props.loading === "setup" && (
                      <div className={classes.linearProgress}>
                        <LinearProgress />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        props.setCambiarDispositivo(true);
                      }}
                      color={"primary"}
                      variant={"contained"}
                    >
                      Cambiar equipo
                    </Button>
                  </>
                )}
              </Container>
              <Container>
                <TextField
                  required
                  style={{
                    width: "100%",
                    marginTop: "15px",
                  }}
                  label="Nombre del técnico"
                  value={props.tecnico}
                  onChange={(e) => props.setTecnico(e.target.value)}
                  variant="outlined"
                />
                <span
                  style={{
                    color: "red",
                    marginTop: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {props.errorConfigurar}
                </span>
              </Container>
              <Container>
                <TextField
                  style={{
                    width: "100%",
                  }}
                  label="Notas (Opcional)"
                  value={props.notas}
                  onChange={(e) => props.setNotas(e.target.value)}
                  multiline
                  variant="outlined"
                />
                <span
                  style={{
                    color: "red",
                    marginTop: 20,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {props.errorConfigurar}
                </span>
                {props.loading !== "setup" && (
                  <Button
                    onClick={() => {
                      setFinalizarActive(true);
                      props.onFinalizar();
                    }}
                    variant={"contained"}
                    color={"primary"}
                    disabled={props.tecnico === "" || finalizarActive}
                  >
                    Finalizar
                  </Button>
                )}
              </Container>
            </Grid>
            <Grid className={classes.root} item sm>
              {!props.cambiarDispositivo && (
                <>
                  {!props.cambioRealizado && (
                    <div style={{ marginTop: 20, marginBottom: 10 }}>
                      <Container>
                        <span>Equipo listo para realizar pruebas.</span>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                          style={{
                            marginTop: 10,
                            height: 100,
                          }}
                        >
                          <Button
                            variant={"contained"}
                            onClick={() => {
                              // console.log("Device", props.dispositivo);
                              // console.log("emnfiy auth", props.emnifyAuthToken);
                              // console.log("comandos", props.comandos);
                              if (
                                props.dispositivo &&
                                props.dispositivo.simId === ""
                              ) {
                                alert(
                                  "Error: Primero debe buscar un Dispositivo"
                                );
                                // setErrorMessage('Primero debe buscar un Dispositivo')
                                return;
                              }

                              // if (props.dispositivo.fechaInstalacion) {
                              // console.log(dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h'));
                              // if (dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h') < 10) {
                              Axios.post(
                                `https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo.emnifyId}/sms`,
                                {
                                  source_address: 2624834189,
                                  payload: `${props.dispositivo.comandos.puertas}`,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${props.dispositivo.emnifyToken}`,
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then((response) => {
                                  // console.log('AxiosResponse', response);
                                  alert("Comando Enviado exitosamente!");
                                })
                                .catch((error) => {
                                  console.log("AxiosError", error);
                                  alert(`Error al enviar el comando: ${error}`);
                                });
                              // } else {
                              //     alert('Error: El tiempo para enviar comandos a éste dispositivo ha expirado.')
                              // }
                              // } else {
                              //     alert('Error: este dispositivo aun no ha sido instalado')
                              // }
                            }}
                          >
                            Abrir Puertas
                          </Button>
                          <Button
                            variant={"contained"}
                            onClick={() => {
                              // console.log("Device", props.dispositivo);
                              // console.log("emnfiy auth", props.emnifyAuthToken);
                              // console.log("comandos", props.comandos);
                              if (props.dispositivo.simId === "") {
                                alert(
                                  "Error: Primero debe buscar un Dispositivo"
                                );
                                return;
                              }
                              // if (props.dispositivo.fechaInstalacion) {
                              // console.log(dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h'));
                              // if (dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h') < 10) {
                              Axios.post(
                                `https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo.emnifyId}/sms`,
                                {
                                  source_address: 2624834189,
                                  payload: `${props.dispositivo.comandos.bloqueo}`,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${props.dispositivo.emnifyToken}`,
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then((response) => {
                                  // console.log('AxiosResponse', response);
                                  alert("Comando Enviado exitosamente!");
                                })
                                .catch((error) => {
                                  console.log("AxiosError", error);
                                  alert(`Error al enviar el comando: ${error}`);
                                });
                              //     } else {
                              //         alert('Error: El tiempo para enviar comandos a éste dispositivo ha expirado.')
                              //     }
                              // } else {
                              //     alert('Error: este dispositivo aun no ha sido instalado')
                              // }
                            }}
                          >
                            Bloquear Motor
                          </Button>
                          <Button
                            variant={"contained"}
                            onClick={() => {
                              if (
                                props.dispositivo &&
                                props.dispositivo.simId === ""
                              ) {
                                alert(
                                  "Error: Primero debe buscar un Dispositivo"
                                );
                                return;
                              }

                              // if (props.dispositivo && props.dispositivo.fechaInstalacion) {
                              //     console.log(dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h'));
                              //     if (dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h') < 10) {
                              Axios.post(
                                `https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo.emnifyId}/sms`,
                                {
                                  source_address: 2624834189,
                                  payload: `${props.dispositivo.comandos.desbloqueo}`,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${props.dispositivo.emnifyToken}`,
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then((response) => {
                                  // console.log('AxiosResponse', response);
                                  alert("Comando Enviado exitosamente!");
                                })
                                .catch((error) => {
                                  console.log("AxiosError", error);
                                  alert(`Error al enviar el comando: ${error}`);
                                });
                              //     } else {
                              //         alert('Error: El tiempo para enviar comandos a éste dispositivo ha expirado.')
                              //     }
                              // } else {
                              //     alert('Error: este dispositivo aun no ha sido instalado')
                              // }
                            }}
                          >
                            Quitar Bloqueos
                          </Button>
                          <Button
                            variant={"contained"}
                            onClick={() => {
                              props.onEnviarComandoGPRS();
                            }}
                          >
                            Conexión Servidor
                          </Button>
                        </Grid>
                      </Container>
                    </div>
                  )}
                  {props.cambioRealizado && (
                    <StatusSMS
                      dispositivo={props.dispositivo}
                      vehiculo={props.vehiculo}
                      onEnviarComandoGPRS={props.onEnviarComandoGPRS}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {!props.vehiculo && (
        <Container maxWidth={"sm"}>
          <TextField
            style={{
              width: "100%",
            }}
            required
            label="Ingrese el Número de instalación"
            value={props.solicitudId}
            onChange={(e) => {
              props.setNumeroInstalacion(e.target.value);
            }}
            // onKeyPress={e => e.key === 'Enter' && props.onObtenerSolicitud()}
            // error={props.errorSolicitud ? true : false}
            // helperText={props.errorSolicitud} />
          />
          <TextField
            style={{
              width: "100%",
              marginBottom: 20,
            }}
            required
            label="Ingrese la clave"
            value={props.claveRevision}
            onChange={(e) => {
              props.setPassword(e.target.value);
            }}
            onKeyPress={(e) => e.key === "Enter" && props.onObtenerVehiculo()}
            error={props.errorSolicitud ? true : false}
            helperText={props.errorSolicitud}
          />
          {props.loading !== "customer" && (
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => props.onObtenerVehiculo()}
              disabled={props.loading !== "" ? true : false}
            >
              Acceder
            </Button>
          )}
          {props.loading === "customer" && (
            <div className={classes.linearProgress}>
              <LinearProgress />
            </div>
          )}
        </Container>
      )}
    </div>
  );
};
