import React from 'react';
// import Axios from 'axios';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import dayjs from 'dayjs';
import { FormLabel } from '@material-ui/core';
import { IPresenterProps } from './types';
import StatusSMS from '../statusSMS';
import "./styles.css";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
    },

    linearProgress: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        marginTop: 10,
        marginBottom: 10,
    }
}));

export default (props: IPresenterProps) => {
    const classes = useStyles()

    const validarChasis = () => {

        const regEx = /^[a-zA-Z0-9]{15,20}$/;

        const esValido = regEx.test(props.vehiculo.chasis) ? true : false;

        return esValido;
    }

    const validarDispositivo = () => {

        if (props.vehiculo.placa === '') {
            return validarChasis();
        } else if (props.vehiculo.chasis === '') {
            return true;
        } else if (props.vehiculo.chasis !== '') {
            return validarChasis();
        }

    }



    return (
        <div>
            <Toolbar variant="regular" style={{ justifyContent: 'center' }}>
                <Typography variant="h5" color="inherit">
                    CONFIGURACIÓN DE DISPOSITIVOS
                </Typography>
            </Toolbar>
            {props.solicitud &&
                <Grid>
                    <Grid container justifyContent={"center"} >
                        <Grid className={classes.root} item sm>
                            <Container >
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    required
                                    label="Número de instalación"
                                    value={props.solicitud.id}
                                    disabled
                                />
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Cliente"
                                    disabled
                                    value={`${props.cliente.nombres} ${props.cliente.apellidos}`}
                                    variant="outlined" />
                                {/* <TextField // se descidio oculata esto con JF
                                    onChange={(event) => {
                                        props.setEmail(event.target.value);
                                    }}
                                    required
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Email"
                                    disabled={true}
                                    value={props.cliente.email}
                                    variant="outlined" /> */}
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Fecha de Vencimiento"
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={props.vehiculo.dateExpire}
                                    disabled
                                    variant="outlined"
                                />
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Vehículo"
                                    disabled
                                    value={`${props.vehiculo.marca} ${props.vehiculo.modelo} ${props.vehiculo.anio}`}
                                    variant="outlined" />
                            </Container>
                            <Container >
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label={"Número de serie"}
                                    placeholder="Ingrese el número de serie"
                                    value={props.dispositivo.serial}
                                    onChange={(e) => {
                                        props.setNumeroSerie(e.target.value);
                                    }}
                                    onKeyPress={e => e.key === 'Enter' && props.onObtenerDispositivo()}
                                    error={props.errorObtnerDispositivo ? true : false}
                                    helperText={props.errorObtnerDispositivo} />
                                {props.loading !== 'device' && <Button variant={"contained"} color={"primary"} onClick={() => props.onObtenerDispositivo()} disabled={props.loading !== '' ? true : false}>Buscar</Button>}
                                {props.loading === 'device' && <div className={classes.linearProgress}>
                                    <LinearProgress />
                                </div>}

                                <TextField style={{
                                    width: '100%'
                                }}
                                    label={"SIM ID"}
                                    disabled={true}
                                    value={props.dispositivo.simId || ''}
                                    variant="outlined" />
                                <TextField
                                    required
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Placa del vehículo"
                                    value={props.vehiculo.placa}
                                    onChange={(e) => {
                                        const withNoSpaces = e.target.value.replace(/\s+/g, '');
                                        props.setPlaca(withNoSpaces);
                                    }} />
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Número de Chasis (Obligatorio solo si no hay placa)"
                                    value={props.vehiculo.chasis}
                                    onChange={(e) => {
                                        const withNoSpaces = e.target.value.replace(/\s+/g, '');
                                        props.setChasis(withNoSpaces);
                                    }} />
                                <TextField
                                    required
                                    style={{
                                        width: '100%',
                                        marginBottom: 20
                                    }}
                                    type="number"
                                    label="Kilometraje"
                                    value={props.vehiculo.kilometraje}
                                    onChange={(e) => props.setKilometraje(e.target.value)} />
                                <Container >
                                    <FormLabel style={{ paddingLeft: 10 }}>Apertura de puertas</FormLabel>
                                    <Checkbox
                                        onClick={() => props.setAperturaDePuertas(!props.vehiculo.aperturaPuertas)}
                                        checked={props.vehiculo.aperturaPuertas}
                                    />
                                </Container>
                                <Container>
                                    <FormLabel style={{ paddingLeft: 10 }}>Bloqueo de Motor</FormLabel>
                                    <Checkbox
                                        onClick={() => props.setBloqueoMotor(!props.vehiculo.bloqueoMotor)}
                                        checked={props.vehiculo.bloqueoMotor}
                                    />
                                </Container>
                                <Container >
                                    <FormLabel style={{ paddingLeft: 10 }}>Boton de panico</FormLabel>
                                    <Checkbox
                                        onClick={() => props.setBotonDePanico(!props.vehiculo.botonPanico)}
                                        checked={props.vehiculo.botonPanico}
                                    />
                                </Container>
                                <TextField style={{
                                    width: '100%'
                                }} required label="Nombre del instalador" id="instalador" value={props.dispositivo.instalador} onChange={(e) => props.setInstalador(e.target.value)} />
                                <TextField
                                    style={{
                                        width: '100%'
                                    }} label="Notas (Opcional)"
                                    value={props.vehiculo.notas}
                                    onChange={(e) => props.setNotas(e.target.value)}
                                    multiline
                                    variant="outlined"
                                />
                                <span style={{ color: 'red', marginTop: 20, fontSize: 16, fontWeight: 'bold' }}>{props.errorConfigurar}</span>
                                {props.loading !== 'setup' && <Button onClick={() => validarDispositivo() ? props.onConfigurarDispositivo() : alert("Número de chasis inválido")} variant={"contained"} color={"primary"} disabled={props.cliente.email === '' || props.dispositivo.simId === '' || (props.vehiculo.placa === '' && props.vehiculo.chasis === '' || props.solicitud.estado === 'instalado')}>Iniciar Configuración</Button>}
                                {props.loading === 'setup' &&
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                                        <h4>Configurando</h4>
                                        <div className={classes.linearProgress}>
                                            <LinearProgress />
                                        </div>
                                    </div>}
                            </Container>
                        </Grid>
                        <Grid className={classes.root} item sm>
                            {props.dispositivo.emnifyId && props.dispositivo.emnifyId !== '' && props.dispositivo && <StatusSMS dispositivo={props.dispositivo} vehiculo={props.vehiculo} onEnviarComandoGPRS={props.onEnviarComandoGPRS} />}
                        </Grid>
                    </Grid>

                </Grid>}
            {!props.solicitud &&
                // <Grid container justifyContent='center' md={6}>
                <Container maxWidth={'sm'}>
                    <TextField
                        style={{
                            width: '100%'
                        }}
                        required
                        label="Ingrese el Número de instalación"
                        value={props.solicitudId}
                        onChange={(e) => {
                            props.setNumeroInstalacion(e.target.value);
                        }}
                    // onKeyPress={e => e.key === 'Enter' && props.onObtenerSolicitud()}
                    // error={props.errorSolicitud ? true : false}
                    // helperText={props.errorSolicitud} />
                    />
                    <TextField
                        style={{
                            width: '100%',
                            marginBottom: 20
                        }}
                        required
                        label="Ingrese la clave"
                        value={props.solicitudClave}
                        onChange={(e) => {
                            props.setPassword(e.target.value);
                        }}
                        onKeyPress={e => e.key === 'Enter' && props.onObtenerSolicitud()}
                        error={props.errorSolicitud ? true : false}
                        helperText={props.errorSolicitud} />
                    {props.loading !== 'customer' && <Button variant={"contained"} color={"primary"} onClick={() => props.onObtenerSolicitud()} disabled={props.loading !== '' ? true : false}>Acceder</Button>}
                    {props.loading === 'customer' && <div className={classes.linearProgress}>
                        <LinearProgress />
                    </div>}
                </Container>
                // </Grid>
            }
        </div>
    );
}