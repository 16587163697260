import React from 'react';
import Presenter from './presenter';
import { IContainerProps, IContainerState } from './types';
import { API } from 'aws-amplify';
import Axios from 'axios';
import dayjs from 'dayjs';

class Container extends React.Component<IContainerProps, IContainerState>{
    constructor(props: any) {
        super(props);
        this.state = {
            loading: '',
            solicitudId: '',
            solicitudClave: '',
            cliente: {
                nombres: '',
                apellidos: '',
                telefono: '',
                email: '',
                ciruc: '',
                ciudad: '',
            },
            dispositivo: {
                imei: '',
                marca: '',
                modelo: '',
                emnifyId: '',
                simId: '',
                serial: '',
                iccid: '',
                instalador: '',
                comandos: {
                    puertas: '',
                    bloqueo: '',
                    desbloqueo: ''
                }
            },
            vehiculo: {
                id: 0,
                marca: '',
                modelo: '',
                anio: '',
                placa: '',
                chasis: '',
                mt: '',
                ramv: '',
                numero: '',
                kilometraje: '',
                notas: '',
                bloqueoMotor: false,
                aperturaPuertas: false,
                botonPanico: false,
                dateExpire: ''
            },
        }
    }

  setNumeroInstalacion = (value: string) => {
    this.setState({
      solicitudId: value,
      errorSolicitud: undefined,
    });
  };

  setPassword = (value: string) => {
    this.setState({
      solicitudClave: value,
      errorSolicitud: undefined,
    });
  };

  setKilometraje = (value: string) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        kilometraje: value,
      },
    });
  };

  setEmail = (value: string) => {
    this.setState({
      cliente: {
        ...this.state.cliente,
        email: value,
      },
    });
  };
  setPlaca = (value: string) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        placa: value,
      },
    });
  };
  setChasis = (value: string) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        chasis: value,
      },
    });
  };
  setNotas = (value: string) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        notas: value,
      },
    });
  };
  setInstalador = (value: string) => {
    this.setState({
      dispositivo: {
        ...this.state.dispositivo,
        instalador: value,
      },
    });
  };
  setNumeroSerie = (value: string) => {
    this.setState({
      dispositivo: {
        ...this.state.dispositivo,
        serial: value,
        simId: "",
      },
      errorObtnerDispositivo: undefined,
    });
  };
  setBotonDePanico = (value: boolean) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        botonPanico: value,
      },
    });
  };
  setAperturaDePuertas = (value: boolean) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        aperturaPuertas: value,
      },
    });
  };
  setBloqueoMotor = (value: boolean) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        bloqueoMotor: value,
      },
    });
  };



  onObtenerSolicitud = () => {
    if (this.state.solicitudId !== "" && this.state.solicitudClave !== "") {
      let nombres = "";
      let apellidos = "";
      let razonSocial = "";
      let telefono = "";
      this.setState(
        {
          loading: "custormer",
        },
        async () => {
          try {
            const resultSolicitud = await API.get(
              `${process.env.REACT_APP_ENV}-instaladores`,
              `/solicitudes?id=${this.state.solicitudId}&clave=${this.state.solicitudClave}`,
              {}
            );
            console.log("resultado api", resultSolicitud);
            const solicitud = resultSolicitud[0];
            // console.log(customer);
            if (solicitud) {
              console.log(new Date(), solicitud.updated_at);
              console.log(
                dayjs(new Date()).diff(dayjs(solicitud.updated_at), "h")
              );
              if (solicitud.estado === "instalado") {
                throw new Error("La solicitud ya ha sido instalada!");
              } else {
                if (
                  solicitud.razon_social === "" ||
                  solicitud.razon_social === null
                ) {
                  nombres = solicitud.nombres;
                  apellidos = solicitud.apellidos;
                } else {
                  razonSocial = solicitud.razon_social;
                }
                if (solicitud.telefono_celular[0] === "+") {
                  telefono = solicitud.telefono_celular.substring(1);
                } else if (solicitud.telefono_celular[0] === "0") {
                  telefono = "593" + solicitud.telefono_celular.substring(1);
                } else {
                  telefono = solicitud.telefono_celular;
                }
                if (solicitud.placa && solicitud.placa !== "") {
                  const withNoSpaces = solicitud.placa.replace(/\s+/g, "");
                  this.setPlaca(withNoSpaces);
                }
                if (solicitud.chasis && solicitud.chasis !== "") {
                  const withNoSpaces = solicitud.chasis.replace(/\s+/g, "");
                  this.setChasis(withNoSpaces);
                }
                const dateExpire = dayjs(new Date())
                  .add(
                    Number(solicitud.tiempo_expiracion.split(" ")[0]),
                    "year"
                  )
                  .format("YYYY-MM-DDTHH:mm");

                this.setState(
                  {
                    cliente: {
                      nombres: solicitud.nombres || "",
                      apellidos: solicitud.apellidos || "",
                      telefono,
                      email: solicitud.email || "",
                      emailFacturaMt: solicitud.email_factura_mt,
                      ciruc: solicitud.ciruc || "",
                      ciudad: solicitud.ciudad || "",
                    },
                    solicitud: {
                      emailFinanciera: solicitud.email_financiera || "",
                      ...solicitud,
                    },
                    vehiculo: {
                      ...this.state.vehiculo,
                      placa: solicitud.placa || "",
                      chasis: solicitud.chasis || "",
                      mt: solicitud.mt || "",
                      marca: solicitud.marca || "",
                      modelo: solicitud.modelo || "",
                      anio: solicitud.anio || "",
                      dateExpire: dateExpire || "",
                    },
                    loading: "",
                  },
                  () => console.log(this.state)
                );
              }
            } else {
              throw new Error("Credenciales Incorrectas!");
            }
          } catch (error) {
            console.log("error", error);
            this.setState({
              loading: "",
              // @ts-ignore
              errorSolicitud: error.toString(),
            });
          }
        }
      );
    } else {
      this.setState({
        errorSolicitud: "Error: Debe ingresar el todos los campos",
      });
    }
  };

  onObtenerDispositivo = () => {
    this.setState({ loading: "device" }, async () => {
      try {
        const deviceFound = await API.get(
          `${process.env.REACT_APP_ENV}-instaladores`,
          `/dispositivos?serial=${this.state.dispositivo.serial}`,
          {}
        );
        console.log("Dispositivo: ", deviceFound);
        if (deviceFound) {
          if (deviceFound.emnifyId && deviceFound.emnifyId !== "") {
            throw new Error(
              "El dispositivo ya ha sido instalado en otro vehículo"
            );
          } else if (!deviceFound.simId) {
            throw new Error(
              "El dispositivo no tiene un chip asignado en el sistema"
            );
          } else {
            this.setState({
              dispositivo: {
                ...this.state.dispositivo,
                imei: deviceFound.imei,
                marca: deviceFound.marca,
                modelo: deviceFound.modelo,
                emnifyId: deviceFound.emnifyId,
                emnifyToken: deviceFound.emnifyToken,
                simId: deviceFound.simId,
                iccid: deviceFound.iccid,
                comandos: {
                  puertas: deviceFound.comandoPuertas,
                  bloqueo: deviceFound.comandoBloqueo,
                  desbloqueo: deviceFound.comandoSinBloqueos,
                },
                instalador: "",
              },
              loading: "",
            });
          }
        } else {
          throw new Error("Dispositivo no encontrado");
        }
      } catch (error) {
        this.setState({
          dispositivo: {
            imei: "",
            marca: "",
            modelo: "",
            emnifyId: "",
            simId: "",
            serial: this.state.dispositivo.serial,
            iccid: "",
            instalador: "",
            comandos: {
              puertas: "",
              bloqueo: "",
              desbloqueo: "",
            },
          },
          // @ts-ignore
          errorObtnerDispositivo: error.toString(),
          loading: "",
        });
      }
    });
  };

  onConfigurarDispositivo = () => {
    this.setState({ loading: "setup" }, async () => {
      if (this.state.solicitud) {
        if (
          this.state.vehiculo.kilometraje !== "" &&
          this.state.dispositivo.instalador !== "" &&
          this.state.cliente.email !== ""
        ) {
          const patronPlaca = /^[A-Za-z]{3}[0-9]{4}$/;
          const patronPlacaMoto = /^[A-Za-z]{2}[0-9]{3}[A-Za-z]{1}$/;
          if (
            (this.state.vehiculo.placa !== "" &&
              (patronPlaca.test(this.state.vehiculo.placa) ||
                patronPlacaMoto.test(this.state.vehiculo.placa))) ||
            (this.state.vehiculo.placa === "" &&
              this.state.vehiculo.chasis !== "")
          ) {
            // get ramv
            // TODO
            // get avaluo
            let valorAvaluo = 0;
            var carFromSRI;
            if (
              this.state.vehiculo.placa !== "" &&
              patronPlaca.test(this.state.vehiculo.placa)
            ) {
              try {
                const avaluoResult = await Axios.get(
                  `https://o5j42hbf20.execute-api.us-east-2.amazonaws.com/dev/get_avaluo?placa=${this.state.vehiculo.placa.toUpperCase()}`
                );
                const avaluo = avaluoResult.data;
                if (avaluo.estado.codigo === "OK") {
                  valorAvaluo = avaluo.personaVehiculo.precioVentaPublico;
                }
              } catch (error) {
                console.log(
                  `Error Avaluo. placa: ${this.state.vehiculo.placa}`,
                  error
                );
              }
              // *****************************
              // obtener ramv
              try {
                const resultSRI = await Axios.get(
                  `https://srienlinea.sri.gob.ec/movil-servicios/api/v1.0/matriculacion/valor/${this.state.vehiculo.placa}`,
                  {
                    timeout: 5000,
                  }
                );
                console.log("Info obtenida del sri", resultSRI);
                carFromSRI = resultSRI.data;
              } catch (error) {
                console.log(
                  `Error SRI placa: ${this.state.vehiculo.placa}`,
                  error
                );
              }
              // fin obtener ramv
            }
            try {
              const resultDevice = await API.post(
                `${process.env.REACT_APP_ENV}-instaladores`,
                `/dispositivos`,
                {
                  body: {
                    ...this.state.dispositivo,
                    emnifyToken: this.state.dispositivo.emnifyToken,
                    placa: this.state.vehiculo.placa.toUpperCase(),
                    mt: this.state.vehiculo.mt.toUpperCase(),
                    ramv: carFromSRI ? carFromSRI.camvCpn : null,
                    marcaVehiculo: this.state.vehiculo.marca,
                    modeloVehiculo: this.state.vehiculo.modelo,
                    anioVehiculo: this.state.vehiculo.anio,
                    nombres: this.state.cliente.nombres,
                    apellidos: this.state.cliente.apellidos,
                    email: this.state.cliente.email,
                    emailFacturaMt: this.state.cliente.emailFacturaMt,
                    ciruc: this.state.cliente.ciruc,
                    kilometraje: this.state.vehiculo.kilometraje,
                    telefono: this.state.cliente.telefono,
                    dateExpire: this.state.vehiculo.dateExpire,
                    instalador: this.state.dispositivo.instalador,
                    notas: this.state.vehiculo.notas,
                    ciudad: this.state.cliente.ciudad,
                    emailFinanciera: this.state.solicitud.emailFinanciera,
                    chasis: this.state.vehiculo.chasis.toUpperCase(),
                    solicitudId: this.state.solicitud.id,
                    valorAvaluo,
                    botonDePanico: this.state.vehiculo.botonPanico,
                    aperturaDePuertas: this.state.vehiculo.aperturaPuertas,
                    bloqueoMotor: this.state.vehiculo.bloqueoMotor,
                    claveRevision: this.state.solicitudClave,
                    agencia: this.state.solicitud.agencia,
                  },
                }
              );
              // console.log("Result device", resultDevice)
              this.setState(
                {
                  dispositivo: {
                    ...this.state.dispositivo,
                    emnifyId: resultDevice.emnifyId,
                  },
                  cliente: {
                    ...this.state.cliente,
                  },
                  vehiculo: {
                    ...this.state.vehiculo,
                    id: resultDevice.id,
                  },
                  solicitud: {
                    ...this.state.solicitud,
                    estado: "instalado",
                  },
                  loading: "",
                },
                () => {
                  alert("Comandos de configuración enviados exitosamente!");
                  // console.log("device after installation", this.state.dispositivo)
                }
              );
            } catch (error) {
              console.log("Error AWS config", error);
              this.setState({
                errorConfigurar:
                  "Ocurrio un error al configurar el dispositivo.",
                loading: "",
              });
            }
          } else {
            this.setState({
              errorConfigurar:
                'La placa de cumplir con el formato: "AAA0123" ó "JJ123A".',
              loading: "",
            });
          }
        } else {
          this.setState({
            errorConfigurar: "Debe llenar todos los campos.",
            loading: "",
          });
        }
      }
    });
  };

  onEnviarComandoGPRS = async () => {
    console.log("Dispositivo Id", this.state.vehiculo.id);
    try {
      const resultDevice = await API.post(
        `${process.env.REACT_APP_ENV}-instaladores`,
        `/gpswox`,
        { body: { device_id: this.state.vehiculo.id } }
      );
      if (resultDevice.error) {
        if (resultDevice.error[0].includes("GPRS")) {
          alert("ERROR: El dispositivo no tiene conexión con el servidor.");
        } else {
          alert(resultDevice.error[0]);
        }
      } else {
        // alert(resultDevice.message)
        alert("OK: El dispositivo tiene conexión con el servidor.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <Presenter
        {...this.props}
        {...this.state}
        setNumeroInstalacion={this.setNumeroInstalacion}
        setPassword={this.setPassword}
        setKilometraje={this.setKilometraje}
        setEmail={this.setEmail}
        setBotonDePanico={this.setBotonDePanico}
        setAperturaDePuertas={this.setAperturaDePuertas}
        setBloqueoMotor={this.setBloqueoMotor}
        setPlaca={this.setPlaca}
        setChasis={this.setChasis}
        setNotas={this.setNotas}
        setInstalador={this.setInstalador}
        setNumeroSerie={this.setNumeroSerie}
        onObtenerSolicitud={this.onObtenerSolicitud}
        onObtenerDispositivo={this.onObtenerDispositivo}
        onConfigurarDispositivo={this.onConfigurarDispositivo}
        onEnviarComandoGPRS={this.onEnviarComandoGPRS}
      />
    );
  }
}

export default Container;