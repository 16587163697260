import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { IProps } from './types';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid } from '@material-ui/core';
// import dayjs from 'dayjs';

const Main = (props: IProps) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<{ message: string }>();
    useEffect(() => {
        setLoading(true);
        const timer = setInterval(() => {
            obtenerMensajes();
        }, 10000);
        const obtenerMensajes = async () => {
            console.log("run")
            // if (props.dispositivo?.emnifyId) {
            try {
                const result = await Axios.get(`https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo?.emnifyId}/sms`, {
                    headers: {
                        'Authorization': `Bearer ${props.dispositivo.emnifyToken}`,
                        'Content-Type': 'application/json'
                    }
                })
                // const result = await Axios.get(`https://cdn.emnify.net/api/v1/endpoint/10230916/sms`, {
                //     headers: {
                //         'Authorization': `Bearer eyJhbGciOiJIUzM4NCJ9.eyJhdWQiOiJcL2FwaVwvdjFcL2F1dGhlbnRpY2F0aW9uIiwiZXNjLmFwcCI6NjIwMiwiYXBpX2tleSI6IklsVm15SmJ4Y1RMSUtCMUZYcXRoNVBxRkNpQjBpNUY1dWx1WTlOQmYiLCJlc2Mub3JnIjoxMDA4NCwiZXNjLm9yZ05hbWUiOiJURUNOSU1PViBTQS4iLCJpc3MiOiJzcGMtZnJvbnRlbmQwMDFAc3BjLWZyb250ZW5kIiwiZXhwIjoxNjYzNjMzODcxLCJpYXQiOjE2NjM2MTk0NzF9.JvWqGgWumgi2IHgFXIGJlHa5JQPPe-v1IZ-Jp1VK6UNPEPhB3gQb2S7yzUn8C_pt`,
                //         'Content-Type': 'application/json'
                //     }
                // })
                // console.log(result);
                const smsArray = result.data;
                const smsFallido: { payload: string } = smsArray.find((sms: any) => sms.payload.includes("Invalid number, no access control"));
                const smsExitoso: { payload: string } = smsArray.find((sms: any) => (
                    sms.payload.includes("OK! Center Number:2624834189") ||
                    sms.payload.includes("New value") ||
                    sms.payload.includes("adminip ok") ||
                    sms.payload.includes(',100,OK')
                ));
                if (smsExitoso) {
                    clearInterval(timer);
                    setData({ message: smsExitoso.payload });
                    setLoading(false);
                } else {
                    if (smsFallido) {
                        await Axios.post(`https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo?.emnifyId}/sms`, {
                            "source_address": 2624834189,
                            "payload": `CENTER,A,2624834189#`
                        }, {
                            headers: {
                                'Authorization': `Bearer ${props.dispositivo.emnifyToken}`,
                                'Content-Type': 'application/json'
                            }
                        })
                    }
                }
            } catch (error) {
                // setLoading(false)
                // clearInterval(timer);
            }
            // }
        }
        return () => clearInterval(timer);
    }, [props.dispositivo, props.dispositivo.emnifyToken]);
    return (
        <div style={{ marginTop: 20, marginBottom: 10 }}>
            {loading ?
                <Container>
                    <span>Finalizando configuración</span>
                    <CircularProgress color="secondary" />
                </Container> :
                <Container>
                    <span>Equipo listo para realizar pruebas.</span>
                    <Grid container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        style={{
                            marginTop: 10,
                            height: 100
                        }}
                    >
                        <Button variant={"contained"} onClick={() => {
                            // console.log("Device", props.dispositivo);
                            // console.log("emnfiy auth", props.emnifyAuthToken);
                            // console.log("comandos", props.comandos);
                            if (props.dispositivo && props.dispositivo.simId === '') {
                                alert('Error: Primero debe buscar un Dispositivo')
                                // setErrorMessage('Primero debe buscar un Dispositivo')
                                return;
                            }

                            // if (props.dispositivo.fechaInstalacion) {
                            // console.log(dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h'));
                            // if (dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h') < 10) {
                            Axios.post(`https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo.emnifyId}/sms`, {
                                "source_address": 2624834189,
                                "payload": `${props.dispositivo.comandos.puertas}`
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${props.dispositivo.emnifyToken}`,
                                    'Content-Type': 'application/json'
                                }
                            }).then(response => {
                                // console.log('AxiosResponse', response);
                                alert('Comando Enviado exitosamente!')
                            }
                            ).catch(error => {
                                console.log('AxiosError', error);
                                alert(`Error al enviar el comando: ${error}`)
                            });
                            // } else {
                            //     alert('Error: El tiempo para enviar comandos a éste dispositivo ha expirado.')
                            // }
                            // } else {
                            //     alert('Error: este dispositivo aun no ha sido instalado')
                            // }
                        }}>Abrir Puertas</Button>
                        <Button variant={"contained"} onClick={() => {
                            // console.log("Device", props.dispositivo);
                            // console.log("emnfiy auth", props.emnifyAuthToken);
                            // console.log("comandos", props.comandos);
                            if (props.dispositivo.simId === '') {
                                alert('Error: Primero debe buscar un Dispositivo')
                                return;
                            }
                            // if (props.dispositivo.fechaInstalacion) {
                            // console.log(dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h'));
                            // if (dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h') < 10) {
                            Axios.post(`https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo.emnifyId}/sms`, {
                                "source_address": 2624834189,
                                "payload": `${props.dispositivo.comandos.bloqueo}`
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${props.dispositivo.emnifyToken}`,
                                    'Content-Type': 'application/json'
                                }
                            }).then(response => {
                                // console.log('AxiosResponse', response);
                                alert('Comando Enviado exitosamente!')
                            }
                            ).catch(error => {
                                console.log('AxiosError', error);
                                alert(`Error al enviar el comando: ${error}`)
                            });
                            //     } else {
                            //         alert('Error: El tiempo para enviar comandos a éste dispositivo ha expirado.')
                            //     }
                            // } else {
                            //     alert('Error: este dispositivo aun no ha sido instalado')
                            // }

                        }}>Bloquear Motor</Button>
                        <Button variant={"contained"} onClick={() => {
                            if (props.dispositivo && props.dispositivo.simId === '') {
                                alert('Error: Primero debe buscar un Dispositivo')
                                return;
                            }

                            // if (props.dispositivo && props.dispositivo.fechaInstalacion) {
                            //     console.log(dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h'));
                            //     if (dayjs(new Date()).diff(dayjs(props.dispositivo.fechaInstalacion), 'h') < 10) {
                            Axios.post(`https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo.emnifyId}/sms`, {
                                "source_address": 2624834189,
                                "payload": `${props.dispositivo.comandos.desbloqueo}`
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${props.dispositivo.emnifyToken}`,
                                    'Content-Type': 'application/json'
                                }
                            }).then(response => {
                                // console.log('AxiosResponse', response);
                                alert('Comando Enviado exitosamente!')
                            }
                            ).catch(error => {
                                console.log('AxiosError', error);
                                alert(`Error al enviar el comando: ${error}`)
                            });
                            //     } else {
                            //         alert('Error: El tiempo para enviar comandos a éste dispositivo ha expirado.')
                            //     }
                            // } else {
                            //     alert('Error: este dispositivo aun no ha sido instalado')
                            // }
                        }}>Quitar Bloqueos</Button>
                        <Button variant={"contained"} onClick={() => {
                            if (props.vehiculo.id === undefined) {
                                alert('Error: Primero debe realizar la configuracion.')
                                return;
                            }
                            props.onEnviarComandoGPRS();

                        }}>Conexión Servidor</Button>
                    </Grid>
                </Container>
            }
            {/* {data?.message} */}
        </div>
    );
}

export default Main;