import React from "react";
import Presenter from "./presenter";
import { withRouter } from "react-router-dom";
import { IContainerProps, IContainerState } from "./types";
import { API } from "aws-amplify";
import dayjs from "dayjs";

class Container extends React.Component<IContainerProps, IContainerState> {

  constructor(props: IContainerProps) {
    super(props);
    this.state = {
      loading: "",
      cambioRealizado: false,
      solicitudId: "",
      claveRevision: "",
      notas: "",
      tecnico: "",
      cliente: {
        nombres: "",
        apellidos: "",
        razonSocial: "",
        telefono: "",
        email: "",
        ciruc: "",
        ciudad: "",
        freshworks_id: "",
      },
      dispositivo: {
        imei: "",
        marca: "",
        modelo: "",
        emnifyId: "",
        simId: "",
        serial: "",
        iccid: "",
        instalador: "",
        comandos: {
          puertas: "",
          bloqueo: "",
          desbloqueo: "",
        },
        emnifyToken: "",
      },
      cambiarDispositivo: false,
    };
  }

  setNumeroInstalacion = (value: string) => {
    this.setState({
      solicitudId: value,
      errorSolicitud: undefined,
    });
  };

  setPassword = (value: string) => {
    this.setState({
      claveRevision: value,
      errorSolicitud: undefined,
    });
  };

  setEmail = (value: string) => {
    this.setState({
      cliente: {
        ...this.state.cliente,
        email: value,
      },
    });
  };
  setNotas = (value: string) => {
    this.setState({
      notas: value,
    });
  };
  setTecnico = (value: string) => {
    this.setState({
      tecnico: value,
    });
  };

  setPlaca = (value: string) => {
    this.state.vehiculo &&
      this.setState({
        vehiculo: {
          ...this.state.vehiculo,
          placa: value,
        },
      });
  };
  setChasis = (value: string) => {
    this.state.vehiculo &&
      this.setState({
        vehiculo: {
          ...this.state.vehiculo,
          chasis: value,
        },
      });
  };
  setInstalador = (value: string) => {
    this.setState({
      dispositivo: {
        ...this.state.dispositivo,
        instalador: value,
      },
    });
  };
  setNumeroSerie = (value: string) => {
    this.setState({
      dispositivo: {
        ...this.state.dispositivo,
        serial: value,
        simId: "",
      },
      errorObtnerDispositivo: undefined,
    });
  };

  setCambiarDispositivo = (value: boolean) => {
    if (value && !this.state.dispositivoAux) {
      this.setState({
        cambiarDispositivo: value,
        dispositivoAux: this.state.dispositivo,
        dispositivo: {
          ...this.state.dispositivo,
          serial: "",
          simId: "",
        },
      });
    } else if (this.state.dispositivoAux) {
      this.setState({
        cambiarDispositivo: value,
        dispositivo: this.state.dispositivoAux,
        dispositivoAux: undefined,
      });
    }
  };

  onObtenerVehiculo = () => {
    if (this.state.solicitudId !== "" && this.state.claveRevision !== "") {
      this.setState(
        {
          loading: "custormer",
        },
        async () => {
          try {
            const resultVehiculo = await API.get(
              `${process.env.REACT_APP_ENV}-instaladores`,
              `/vehiculos?solicitudId=${this.state.solicitudId}&claveRevision=${this.state.claveRevision}`,
              {}
            );
            console.log("resultado api", resultVehiculo);
            const vehiculo = resultVehiculo[0];
            if (vehiculo) {
              console.log(new Date(), vehiculo.fecha_revision);
              console.log(
                dayjs(new Date()).diff(dayjs(vehiculo.fecha_revision), "h")
              );
              if (
                vehiculo.fecha_revision ||
                dayjs(new Date()).diff(dayjs(vehiculo.fecha_instalacion), "h") <
                  5
              ) {
                const resultDispositivo = await API.get(
                  `${process.env.REACT_APP_ENV}-instaladores`,
                  `/dispositivos?serial=${vehiculo.serial_number}`,
                  {}
                );
                this.setState({
                  vehiculo: {
                    id: vehiculo.id,
                    emnifyId: vehiculo.emnify_id,
                    placa: vehiculo.placa,
                    chasis: vehiculo.chasis === null ? "" : vehiculo.chasis,
                    mt: vehiculo.mt,
                    marca: vehiculo.marca,
                    modelo: vehiculo.modelo,
                    anio: vehiculo.anio,
                    ramv: vehiculo.ramv,
                    solicitudId: vehiculo.solicitud_id,
                    fecha_revision: vehiculo.fecha_revision,
                    freshworks_id: vehiculo.vehiculo_freshworks_id,
                  },
                  dispositivo: {
                    serial: vehiculo.serial_number,
                    iccid: resultDispositivo.iccid,
                    simId: vehiculo.sim_id,
                    imei: vehiculo.imei,
                    marca: vehiculo.marca_dispositivo,
                    modelo: vehiculo.modelo_dispositivo,
                    emnifyId: vehiculo.emnify_id,
                    emnifyToken: resultDispositivo.emnifyToken,
                    comandos: {
                      puertas: resultDispositivo.comandoPuertas,
                      bloqueo: resultDispositivo.comandoBloqueo,
                      desbloqueo: resultDispositivo.comandoSinBloqueos,
                    },
                  },
                  cliente: {
                    ...this.state.cliente,
                    nombres: vehiculo.nombres,
                    apellidos: vehiculo.apellidos,
                    email: vehiculo.email,
                    ciruc: vehiculo.ciruc,
                    freshworks_id: vehiculo.usuario_freshworks_id,
                  },
                  loading: "",
                });
              } else {
                throw new Error(
                  "El acceso a esta revision se encuentra desactivado!"
                );
              }
            } else {
              throw new Error("Credenciales Incorrectas!");
            }
          } catch (error) {
            console.log("error", error);
            this.setState({
              loading: "",
              // @ts-ignore
              errorSolicitud: error.toString(),
            });
          }
        }
      );
    } else {
      this.setState({
        errorSolicitud: "Error: Debe ingresar el todos los campos",
      });
    }
  };

  onObtenerDispositivo = () => {
    this.setState({ loading: "device" }, async () => {
      try {
        const deviceFound = await API.get(
          `${process.env.REACT_APP_ENV}-instaladores`,
          `/dispositivos?serial=${this.state.dispositivo.serial}`,
          {}
        );
        if (deviceFound) {
          if (deviceFound.emnifyId && deviceFound.emnifyId !== "") {
            throw new Error(
              "El dispositivo ya ha sido instalado en otro vehículo"
            );
          }
          this.setState({
            dispositivo: {
              ...this.state.dispositivo,
              imei: deviceFound.imei,
              marca: deviceFound.marca,
              modelo: deviceFound.modelo,
              emnifyId: deviceFound.emnifyId,
              simId: deviceFound.simId,
              serial: deviceFound.serial,
              iccid: deviceFound.iccid,
              comandos: {
                puertas: deviceFound.comandoPuertas,
                bloqueo: deviceFound.comandoBloqueo,
                desbloqueo: deviceFound.comandoSinBloqueos,
              },
              instalador: "",
            },
            loading: "",
          });
        } else {
          throw new Error("Dispositivo no encontrado");
        }
      } catch (error) {
        this.setState({
          dispositivo: {
            ...this.state.dispositivo,
            imei: "",
            marca: "",
            modelo: "",
            emnifyId: "",
            simId: "",
            serial: this.state.dispositivo.serial,
            instalador: "",
          },
          // @ts-ignore
          errorObtnerDispositivo: error.toString(),
          loading: "",
        });
      }
    });
  };

  onConfigurarDispositivo = () => {
    this.setState({ loading: "setup" }, async () => {
      try {
        const resultDevice = await API.put(
          `${process.env.REACT_APP_ENV}-instaladores`,
          `/dispositivos`,
          {
            body: {
              ...this.state.dispositivo,
              emnifyId: this.state.dispositivoAux?.emnifyId,
              nombres: this.state.cliente.nombres,
              apellidos: this.state.cliente.apellidos,
              email: this.state.cliente.email,
              ciruc: this.state.cliente.ciruc,
              telefono: this.state.cliente.telefono,
              instalador: this.state.dispositivo.instalador,
              ciudad: this.state.cliente.ciudad,
              solicitudId: this.state.vehiculo?.solicitudId,
              marcaVehiculo: this.state.vehiculo?.marca,
              modeloVehiculo: this.state.vehiculo?.modelo,
              anioVehiculo: this.state.vehiculo?.anio,
              placa: this.state.vehiculo?.placa,
              chasis: this.state.vehiculo?.chasis,
              device_id: this.state.vehiculo?.id,
            },
          }
        );
        this.state.vehiculo &&
          this.setState(
            {
              dispositivo: {
                ...this.state.dispositivo,
                emnifyId: resultDevice.emnifyId,
              },
              cliente: {
                ...this.state.cliente,
                user_api_hash: resultDevice.user_api_hash,
              },
              vehiculo: {
                ...this.state.vehiculo,
                id: resultDevice.id,
              },
              cambioRealizado: true,
              cambiarDispositivo: false,
              loading: "",
              dispositivoAux: undefined,
            },
            () => {
              alert(
                "Cambio Realizado, Los comandos de configuración se han enviado!"
              );
              // console.log("device after installation", this.state.dispositivo)
            }
          );
      } catch (error) {
        console.log("Error AWS config", error);
        this.setState({
          errorConfigurar: "Ocurrio un error al configurar el dispositivo.",
          loading: "",
        });
      }
    });
  };

  onFinalizar = async () => {
    try {
      const resultDevice = await API.patch(
        `${process.env.REACT_APP_ENV}-instaladores`,
        `/dispositivos`,
        {
          body: {
            ...this.state.dispositivo,
            emnifyId: this.state.vehiculo?.emnifyId,
            nombres: this.state.cliente.nombres,
            apellidos: this.state.cliente.apellidos,
            email: this.state.cliente.email,
            ciruc: this.state.cliente.ciruc,
            telefono: this.state.cliente.telefono,
            instalador: this.state.dispositivo.instalador,
            ciudad: this.state.cliente.ciudad,
            solicitudId: this.state.vehiculo?.solicitudId,
            marcaVehiculo: this.state.vehiculo?.marca,
            modeloVehiculo: this.state.vehiculo?.modelo,
            anioVehiculo: this.state.vehiculo?.anio,
            placa: this.state.vehiculo?.placa,
            chasis: this.state.vehiculo?.chasis,
            device_id: this.state.vehiculo?.id,
            notas: this.state.notas,
          },
        }
      );

      const resultRevision = await API.post(
        `${process.env.REACT_APP_ENV}-instaladores`,
        "/revisiones",
        {
          body: {
            solicitud_id: this.state.vehiculo?.solicitudId,
            tecnico_responsable: this.state.tecnico,
            notas: this.state.notas,
          },
        }
      );
      alert("La revisión ha sido registrada exitosamente");
      console.log(resultDevice);
      console.log(resultRevision);
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert("Error: Ocurrio un error al registrar la revisión");
    }
  };

  onEnviarComandoGPRS = async () => {
    if (this.state.vehiculo) {
      console.log("Dispositivo Id", this.state.vehiculo.id);
      try {
        const resultDevice = await API.post(
          `${process.env.REACT_APP_ENV}-instaladores`,
          `/gpswox`,
          { body: { device_id: this.state.vehiculo.id } }
        );
        if (resultDevice.error) {
          if (resultDevice.error[0].includes("GPRS")) {
            alert("ERROR: El dispositivo no tiene conexión con el servidor.");
          } else {
            alert(resultDevice.error[0]);
          }
        } else {
          // alert(resultDevice.message)
          alert("OK: El dispositivo tiene conexión con el servidor.");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    return (
      <Presenter
        {...this.props}
        {...this.state}
        setNumeroInstalacion={this.setNumeroInstalacion}
        setPassword={this.setPassword}
        setEmail={this.setEmail}
        setPlaca={this.setPlaca}
        setChasis={this.setChasis}
        setInstalador={this.setInstalador}
        setNumeroSerie={this.setNumeroSerie}
        setNotas={this.setNotas}
        setTecnico={this.setTecnico}
        setCambiarDispositivo={this.setCambiarDispositivo}
        onObtenerVehiculo={this.onObtenerVehiculo}
        onObtenerDispositivo={this.onObtenerDispositivo}
        onConfigurarDispositivo={this.onConfigurarDispositivo}
        onEnviarComandoGPRS={this.onEnviarComandoGPRS}
        onFinalizar={this.onFinalizar}
      />
    );
  }
}

export default Container;
