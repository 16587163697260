
import React from 'react';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import BloqueoScreen from './Components/Bloqueo';
import InstaladoresScreen from './Components/instaladores/container';
import RevisionesScreen from './Components/revisiones/container';

const theme = createTheme({
	palette: {
		primary: {
			main: '#E5332A'
		},
		secondary: {
			main: blue[500]
		},
		text: {
			disabled: grey[800]
		}
	}
});

function App() {
	return (<BrowserRouter>
		<ThemeProvider theme={theme}>
			<div>
				<AppBar position="static" >
					<Toolbar variant="regular" style={{ justifyContent: 'center' }}>
						<Typography variant="h5" color="inherit">
							{process.env.REACT_APP_ENV === 'dev' ? 'NAVITRAC-QA' : 'NAVITRAC'}
						</Typography>

					</Toolbar>
					<div style={{ flexDirection: 'row', marginBottom: 5 }}>
						<Link style={{ margin: 10, color: 'white', textDecoration: 'none' }} to={"/"}>Instaladores</Link>
						<Link style={{ margin: 10, color: 'white', textDecoration: 'none' }} to={"/revisiones"}>Revisiones</Link>
						{/* <Link style={{ margin: 10, color: 'white', textDecoration: 'none' }} to={"/flotas"}>Flotas</Link> */}
						<Link style={{ margin: 10, color: 'white', textDecoration: 'none' }} to={"/bloqueos"}>Bloqueos</Link>
					</div>
				</AppBar>
			</div>
			<Switch>
				<Route exact path={'/'} render={(props) => <InstaladoresScreen />} />
				{/* <Route path={'/flotas'} component={InstaladoresFlotasScreen} /> */}
				<Route path={'/bloqueos'} component={BloqueoScreen} />
				<Route path={'/revisiones'} render={(props) => <RevisionesScreen />} />
			</Switch>
		</ThemeProvider>
	</BrowserRouter>
	);
}

export default App;
